<template>
  <div class="chart-wrap">
    <div
      class="chart-card"
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      "
    >
      <div class="chart-card__header">
        <h2 class="mi">{{ title }}</h2>
        <!-- <el-dropdown>
          <span class="el-dropdown-link">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>Action 1</el-dropdown-item>
            <el-dropdown-item>Action 2</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
      <apexchart
        ref="chart"
        type="donut"
        width="250"
        style="margin: 0 auto"
        :options="chartOptions"
        :series="series"
      >
      </apexchart>
      <div class="statistic">
        <div
          v-for="(item, index) in statistic"
          :key="index"
          class="statistic__item"
        >
          <span>{{ item.name }}</span>
          <p class="count">{{ item.count | formatMoney }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import formatNumber from "@/utils/formatNumber.js";
import { i18n } from '@/utils/i18n';
export default {
  props: ["data", "title"],
  data() {
    return {
      show: false,
      series: [],

      chartOptions: {
        theme: {
          mode: "light",
        },
        chart: {
          background: "transparent",
          width: 380,
          type: "donut",
        },
        labels: [],
        plotOptions: {
          pie: {
            startAngle: 0,
            // endAngle: 270
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          show: false,
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        colors: ["#8b5cf6", "#0ea5e9", "#f59e0b", "#10b981"],
      },

      statistic: [],
    };
  },
  watch: {
    data: function (newVal, oldVal) {
      this.setData(newVal);
    },
    mode(bool) {
      this.updateChart(bool);
    },
  },
  mounted() {
    this.setData(this.data);
    this.updateChart(this.mode);
  },
  computed: {
    ...mapGetters({
      paymentTypes: "paymentTypes/list",
      mode: "theme/MODE",
    }),
  },
  methods: {
    formatNumber,
    setData(val) {
      this.show = false;

      this.series = [];
      this.statistic = [];
      this.chartOptions.labels = [];
      for (const key in val) {
        const element = val[key];
        let name = this.paymentTypes.find((paymentTypesitem) => {
          return paymentTypesitem.id == key;
        })
          ? this.paymentTypes.find((paymentTypesitem) => {
              return paymentTypesitem.id == key;
            }).name
          : i18n.t("message.totaly");

        if (name != i18n.t("message.totaly")) {
          this.chartOptions.labels.push(name);
          this.series.push(parseFloat(element));
        }

        this.statistic.push({
          name: name,
          count: element,
        });
      }
      this.show = true;
    },

    updateChart(bool) {
      this.$refs.chart.updateOptions({
        theme: {
          mode: bool ? "dark" : "light",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.statistic {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;
  padding: 0 20px;
  border-top: 1px solid #e5e7eb;
  margin-top: 1rem;

  // statistic__item
  &__item {
    text-align: center;

    span {
      position: relative;
      font-size: 14px;
      font-weight: 500;
      color: #9ca3af;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -12px;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #8b5cf6;
      }
    }

    .count {
      font-weight: 700;
      font-size: 16px;
      color: #444;
    }

    &:nth-child(2) span::before {
      background: #0ea5e9;
    }

    &:nth-child(3) span::before {
      background: #f59e0b;
    }

    &:nth-child(4) span::before {
      background: #10b981;
    }
  }
}
</style>
<template>
  <div class="chart-wrap">
    <div class="chart-card">
      <div class="chart-card__header">
        <h2>{{ $t("message.receive") }}</h2>
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ $t(`message.${selected_item}`)
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in dateItems"
              @click.native="selected_item = item"
              :key="item"
              >{{ $t(`message.${item}`) }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="chart-card__statistic">
        <div
          class="chart-card__statistic-item"
          v-for="(item, index) in statistic"
          :key="index"
        >
          <h3 class="count">{{ item.count }}</h3>
          <p class="subtitle">{{ item.subtitle }}</p>
        </div>
      </div>

      <div>
        <apexchart
          type="area"
          height="300"
          :options="chartOptions"
          ref="chart"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  props: ["data", "active_filter"],
  data() {
    return {
      selected_item: "daily",
      dateItems: [
        "daily",
        "weekly",
        "monthly",
        "quarterly",
        "half_yearly",
        "yearly",
      ],
      statistic: [
        {
          count: 1117,
          subtitle: "Total subscriptions",
        },
        {
          count: 742,
          subtitle: "Basic subscriptions",
        },
        {
          count: 259,
          subtitle: "Pro subscriptions",
        },
      ],

      series: [],

      chartOptions: {
        theme: {
          mode: "light",
        },
        chart: {
          background: "transparent",
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: "smooth",
        },
        xaxis: {
          categories: [],
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
        colors: ["#8b5cf6", "#0ea5e9"],
      },
    }
  },
  watch: {
    data: function (newVal, oldVal) {
      this.setData(newVal, this.active_filter)
    },
    active_filter: function (newVal, oldVal) {
      this.setData(this.data, this.active_filter)
    },
    selected_item: function (newVal, oldVal) {
      this.setData(this.data, newVal)
    },

    mode(bool) {
      this.updateChart(bool)
    },
  },
  mounted() {
    this.setData(this.data, "monthly")

    this.updateChart(this.mode)
  },
  computed: {
    ...mapGetters({
      paymentTypes: "paymentTypes/list",
      mode: "theme/MODE",
    }),
  },
  methods: {
    setData(val, active_filter) {
      if (active_filter != "totaly" && active_filter != "filter") {
        this.series = []
        this.statistic = []
        this.chartOptions.xaxis.categories = []
        const chart = this.$refs.chart
        for (const key in val) {
          const element = val[key]

          let name = this.paymentTypes.find((paymentTypesitem) => {
            return paymentTypesitem.id == key
          })
            ? this.paymentTypes.find((paymentTypesitem) => {
                return paymentTypesitem.id == key
              }).name
            : ""
          this.series.push({
            name: name,
            data: element[active_filter].map((item) => item.summ),
          })
          chart.updateOptions({
            xaxis: {
              categories: element[active_filter].map((item) => item.data),
            },
          })
        }
      }
    },
    updateChart(bool) {
      this.$refs.chart.updateOptions({
        theme: {
          mode: bool ? "dark" : "light",
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.chart-card {
  // chart-card__statistic
  &__statistic {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 150px;

    // chart-card__statistic-item
    &-item {
      .count {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        position: relative;
      }

      .subtitle {
        color: #9ca3af;
        font-size: 13px;
        margin: 0;
      }

      &:nth-child(2) .count::before,
      &:last-child .count::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -14px;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #8b5cf6;
      }

      &:last-child .count::before {
        background: #0ea5e9;
      }
    }
  }
}
</style>

<style lang="scss">
.chart-card__header .el-dropdown-link {
  margin: 0;
  border: none;
}
</style>
